<template>
  <div class="configure">
		<div class="search-box">
			<el-form :inline="true" :model="formSearch" class="demo-form-inline" label-width="100px">
				<el-form-item label="操作名称">
					<el-input v-model="formSearch.name" placeholder="请输入操作名称"></el-input>
				</el-form-item>
				<el-form-item label="子系统">
					<el-select v-model="formSearch.operateSystem" clearable>
						<el-option v-for="(item,index) in formSearch.sonSystemList" :key="index" :label="item" :value="item"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="模块">
					<el-input v-model="formSearch.operateModule" placeholder="请输入模块"></el-input>
				</el-form-item>
				<el-form-item style="text-align: right;" class="fr">
					<el-button type="primary" @click="searchNameFn">检索</el-button>
					<el-button type="success" @click="handleAdd">新增</el-button>
				</el-form-item>
			</el-form>
		</div>
		<!-- 列表 -->
		<div class="table-box">
			<el-card>
				<el-table v-loading="loadingFlag" border :data="tableData" :row-style="{height:'48px'}" empty-text="加载中..." style="width: 100%;">
					<el-table-column type="index" :index="typeIndex" label="序号" width="50"></el-table-column>
					<el-table-column prop="name" label="操作名称" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="operateSystem" label="子系统" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="operateModule" label="模块" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="path" label="路径" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="status" label="状态" width="100">
						<template slot-scope="scope">
							<span v-if="scope.row.status == '0'">有效</span>
							<span v-if="scope.row.status == '1'">无效</span>
						</template>
					</el-table-column>
					<el-table-column prop="" label="操作" width="100">
						<template slot-scope="scope">
							<span @click="handleEdit(scope.$index, scope.row)" style="cursor: pointer;" class="color-m">编辑</span>
							|
							<span @click="handDelete(scope.$index, scope.row)" style="color: red;cursor: pointer;">删除</span>
						</template>
					</el-table-column>
					<template slot="empty">
						<img v-if="dataTableEmpty" class="data-pic" :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;" />
					</template>
				</el-table>
				<el-pagination @current-change="handleCurrentChange" :total="totalItems" :current-page="currentPage" :page-size="pageSize" layout="total, prev, pager, next, jumper"></el-pagination>
			</el-card>
		</div>
		<!-- 添加  编辑     弹框 -->
		<el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%">
			<el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="操作名称" prop="name">
					<el-input v-model="ruleForm.name" placeholder="请输入操作名称"></el-input>
				</el-form-item>
				<el-form-item label="子系统" prop="operateSystem">
					<el-select v-model="ruleForm.operateSystem" placeholder="请选择子系统">
						<el-option v-for="(item,index) in ruleForm.systemList" :key="index" :label="item" :value="item"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="模块" prop="operateModule">
					<el-input v-model="ruleForm.operateModule" placeholder="请输入模块"></el-input>
				</el-form-item>
				<el-form-item label="路径" prop="path">
					<el-input v-model="ruleForm.path" placeholder="请输入路径"></el-input>
				</el-form-item>
				<el-form-item label="状态" prop="status">
					<el-select v-model="ruleForm.status" placeholder="请选择状态">
						<el-option label="有效" value="0"></el-option>
						<el-option label="无效" value="1"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
			</span>
		</el-dialog>
  </div>
</template>

<script>
import { exportExcel } from '../../provider/index.js'
export default {
  name: 'configure',
  data() {
		return {
			baseUrl:this.$pulick.baseUrl,//默认路径目录  加载静态文件img
			formSearch:{
				name: '',
				operateModule: '',
				operateSystem: '',
				sonSystemList: [],
			},
			loadingFlag: false,
			dataTableEmpty:false,
			tableData: [], //列表  数组
			currentPage: 1, //当前页
			pageSize: 13, //每页  条数
			totalItems: 0, //列表 总数
			dialogVisible: false,
			dialogTitle: "新增",
			ruleForm: {
				id: '',
				name: '',
				operateSystem: '',
				operateModule: '',
				path: '',
				status: '',
				systemList: [],
				moduleList: [],
			},
		};
  },
  mounted() {}, 
  created() {
		this.getSonSystem();//加载子系统
		this.getPageList(); //加载列表
  },
  methods: {
		//加载子系统
		getSonSystem() {
			this.$provider.get('/wg-system/api/level', {}).then(res=>{
				this.formSearch.sonSystemList = res.data;
				this.ruleForm.systemList = res.data;
			})
		},
		//点击查询  按钮
		searchNameFn() {
			this.currentPage = 1; //初始第一页
			this.getPageList(); //加载列表
		},
		//加载列表
		getPageList() {
			this.loadingFlag = true;//loading 显示
			this.dataTableEmpty = false;
			this.$provider.get('/wg-system/api/page',{
				params:{
					name: this.formSearch.name,
					operateModule: this.formSearch.operateModule,
					operateSystem: this.formSearch.operateSystem,
					current: this.currentPage,
					size: this.pageSize,
				}
			}).then(res=>{
				this.loadingFlag = false;//loading 消失
				this.dataTableEmpty = true;
				this.tableData = res.data.records;
				this.totalItems = res.data.total;
			})
		},
		//处理分页数据的 index
		typeIndex(index) {
			return (this.currentPage - 1) * this.pageSize + index + 1;
		},
		//当前页面  属于第几页
		handleCurrentChange(val) {
			this.currentPage = val;
			this.getPageList(); //加载列表
		},
		//删除
		handDelete(index,item){
			if(confirm("确定删除本条数据吗？")){
				this.$provider.get('/wg-system/api/delele', {
					params: {
						id: item.id,
					}
				}).then(res => {
					if (res.code == 200) {
						this.$message({
							message: '删除成功',
							type: 'success'
						});
						this.getPageList();
					} else {
						this.$message.error('删除失败');
						return false;
					}
				})
			}
		},
		//编辑  弹框打开
		handleEdit(index,item) {
			this.dialogVisible = true; //打开弹框
			this.dialogTitle = "编辑";
			this.ruleForm.id = item.id;
			this.ruleForm.name = item.name;
			this.ruleForm.operateSystem = item.operateSystem;
			this.ruleForm.operateModule = item.operateModule;
			this.ruleForm.path = item.path;
			this.ruleForm.status = item.status;
		},
		//添加  弹框打开
		handleAdd() {
			this.dialogVisible = true; //打开弹框
			this.dialogTitle = "新增";
			this.ruleForm.id = '';
			this.ruleForm.name = '';
			this.ruleForm.operateSystem = '';
			this.ruleForm.operateModule = '';
			this.ruleForm.path = '';
			this.ruleForm.status = '';
		},
		//添加  编辑  确定
		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.$provider.post('/wg-system/api/save', {
						id: this.ruleForm.id,
						name: this.ruleForm.name,
						operateSystem: this.ruleForm.operateSystem,
						operateModule: this.ruleForm.operateModule,
						path: this.ruleForm.path,
						status: this.ruleForm.status,
					}).then(res => {
						if (res.code == 200) {
							this.$message({
								message: '成功',
								type: 'success'
							});
							this.dialogVisible = false; //关闭弹框
							this.getPageList();
						} else {
							this.$message.error('失败');
							return false;
						}
					})
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
  }
};
</script>

<style scoped="scoped"></style>
